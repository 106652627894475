html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.signup-container {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.login-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;
  text-align: center;
}

.login-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  width: auto;
  align-self: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo {
  width: auto;
  height: 60px;
}

@media (max-width: 600px) {
  .logo {
    height: 40px;
  }
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.signup-box {
  background-color: white;
  width: 100%;
  max-width: 700px;
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .signup-box {
    flex-direction: row;
  }
}

.left-side, .right-side {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .left-side, .right-side {
    width: 50%;
  }
}

.account-type {
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}

.symbol {
  font-size: 24px;
  margin-right: 15px;
  vertical-align: middle;
}

.account-type h3 {
  display: inline-block;
  margin-bottom: 20px;
}

.account-type ul {
  list-style: none;
  padding-left: 0;
}

.account-type:not(:last-child):after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background-color: #ccc;
  opacity: 0.5;
}

.signup-form {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.signup-form input, .signup-form select {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.terms-checkbox {
  margin: 20px 0;
}

.signup-form button {
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.signup-form button:disabled {
  background-color: #CCC;
  cursor: not-allowed;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.password-check-icon {
  color: green;
  margin-left: 5px;
}

.password-times-icon {
  color: red;
  margin-left: 5px;
}

.error-box {
  background-color: #ffcdd2;
  color: #d32f2f;
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px;
  position: relative;
}

.error-box button {
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}