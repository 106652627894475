.dashboard-container {
  padding: 20px;
  background-color: #f8f9fa;
}

.profile-incomplete-overlay {
  position: relative;
}

.profile-incomplete-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(4px);
  z-index: 1;
  pointer-events: none;
}

/* Card styling */
.dashboard-card {
  height: 100%;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

/* Table styling */
.MuiTableContainer-root {
  border-radius: 0;
}

.MuiTableHead-root .MuiTableCell-root {
  background-color: #f5f5f5;
  font-weight: 600;
}

.MuiTableRow-hover:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Status indicators */
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.status-pending {
  background-color: #f57c00;
}

.status-approved {
  background-color: #4caf50;
}

.status-rejected {
  background-color: #f44336;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .dashboard-container {
    padding: 16px;
  }
  
  .summary-cards {
    margin-bottom: 16px;
  }
}

@media (max-width: 600px) {
  .dashboard-container {
    padding: 12px;
  }
  
  .MuiCard-root {
    margin-bottom: 16px;
  }
  
  .MuiCardHeader-root {
    padding: 12px;
  }
  
  .MuiCardContent-root {
    padding: 12px;
  }
}

/* Animation for loading states */
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.loading-pulse {
  animation: pulse 1.5s infinite ease-in-out;
}

/* Calendar styling */
.calendar-day {
  position: relative;
  min-height: 60px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
}

.calendar-day:hover {
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 1px #1976d2;
}

.calendar-day.today {
  background-color: #e8f5e9;
  font-weight: bold;
}

.calendar-day.has-bookings {
  background-color: #fff8e1;
}

.calendar-day-number {
  position: absolute;
  top: 5px;
  left: 5px;
  font-weight: 500;
}

.calendar-day-badge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #1976d2;
  color: white;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}

.calendar-day-badge.high {
  background-color: #f44336;
}

/* Custom scrollbar for tables */
.MuiTableContainer-root::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}

/* Empty state styling */
.empty-state {
  padding: 40px 20px;
  text-align: center;
  color: #757575;
}

.empty-state svg {
  font-size: 48px;
  margin-bottom: 16px;
  color: #bdbdbd;
}